import logo from './images/mag_round.svg';
import white_logo from './images/mag_round_white.svg'
import bottom_app_img from './images/bottom_app.png'
import bilibili_icon from './images/bilibili.svg'
import douyin_icon from './images/douyin.svg'
import weibo_icon from './images/weibo.svg'
import xiaohongshu_icon from './images/xiaohongshu.svg'
import './App.css';
import React, {useEffect} from "react";

function App() {
    useEffect(() => {
        const backToTopButton = document.getElementById('back-to-top');

        const backToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        };

        backToTopButton.addEventListener('click', backToTop)

        return () => {
            backToTopButton.removeEventListener('click', backToTop)
        }

    })

    return (
        <div className="App">
            <header>
                <div className="header">
                    <a href='http://www.letsmag.com' className="disabled-select">
                        <img className="logo" src={white_logo} alt="Logo"/>
                    </a>
                    <div className="navigation">
                        <a href="#features" className="nav-item">
                            <span>特色</span>
                            <svg viewBox="0 0 13 20">
                                <polyline points="0.5 19.5 3 19.5 12.5 10 3 0.5"/>
                            </svg>
                        </a>
                        <a href="#services" className="nav-item">
                            <span>服务</span>
                            <svg viewBox="0 0 13 20">
                                <polyline points="0.5 19.5 3 19.5 12.5 10 3 0.5"/>
                            </svg>
                        </a>
                        <a href="#about" className="nav-item">
                            <span>关于</span>
                            <svg viewBox="0 0 13 20">
                                <polyline points="0.5 19.5 3 19.5 12.5 10 3 0.5"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </header>
            <main className="main-content">
                <div className="hero">
                    <div className="title">滑出独一无二</div>
                    <div className="feature-card-container">
                        <div className="feature-card">
                            <img className="logo-image" src={logo} alt="Logo" />
                        <div className="card-title">Mag</div>
                    </div>
                    </div>

                    <div className="title">更懂你</div>
                    <div className="subtitle">从今天开始<br/>升级最丝滑的购物体验</div>
                    <button className="action-button">
                        抢先体验
                        <div className="arrow-wrapper">
                            <div className="arrow"></div>
                        </div>
                    </button>
                </div>
                <div className="bottom-content disabled-all">
                    <img className="bottom-app-img" src={bottom_app_img} alt="Img"/>
                </div>
            </main>
            <footer className="page-footer">
                <div className="subscribe-container">
                    <div className="subscribe-text">关注我们</div>
                    <div className="subscribe-icon-links-container disabled-select">
                        <span className="subscribe-icon-item">
                            <a href="https://weibo.com/" target="_blank" className="subscribe-icon-links">
                                <img id="weibo" className="subscribe-icon" src={weibo_icon} alt="weibo_icon"/>
                            </a>
                        </span>
                        <span className="subscribe-icon-item">
                            <a href="https://bilibili.com/" target="_blank" className="subscribe-icon-links">
                                <img id="bilibili" className="subscribe-icon" src={bilibili_icon} alt="weibo_icon"/>
                            </a>
                        </span>
                        <span className="subscribe-icon-item">
                            <a href="https://douyin.com/" target="_blank" className="subscribe-icon-links">
                                <img id="douyin" className="subscribe-icon" src={douyin_icon} alt="douyin_icon"/>
                            </a>
                        </span>
                        <span className="subscribe-icon-item">
                            <a href="https://xiaohongshu.com/" target="_blank" className="subscribe-icon-links">
                                 <img id="xiaohongshu" className="subscribe-icon" src={xiaohongshu_icon} alt="weibo_icon"/>
                            </a>
                        </span>
                    </div>


                </div>

                <div className="footer-content-links">
                    <a href="#contact">
                        <span>联系我们</span>
                    </a>
                    <a href="#feedback">
                        <span>反馈问题</span>
                    </a>
                    <a href="#private">
                        <span>隐私政策</span>
                    </a>
                </div>


                <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferre noreferrer" className='icp-text-footer'>京ICP备2024083910号-1</a>


            </footer>

            <button className="back-top-button" id="back-to-top">
                <div className="text">
                    <span>Back</span>
                    <span>to</span>
                    <span>top</span>
                </div>
                <div className="clone">
                    <span>Back</span>
                    <span>to</span>
                    <span>top</span>
                </div>
                <svg strokeWidth="2" stroke="currentColor" viewBox="0 0 24 24" fill="none" className="h-6 w-6" width="20px">
                    <path d="M14 5l7 7m0 0l-7 7m7-7H3" strokeLinejoin="round" strokeLinecap="round"/>
                </svg>
            </button>
        </div>

  );
}

export default App;
